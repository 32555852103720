import { createContext, PropsWithChildren } from "react";
import { createContextualCan, useAbility } from "@casl/react";
import { Ability, RawRuleOf } from "@casl/ability";
import { useAuthContext } from "./AuthContext";

export type Actions = "read" | "create" | "update" | "delete";
export type Subjects =
  | "User"
  | "Role"
  | "Zone"
  | "Category"
  | "Parkomats"
  | "Parking"
  | "Tariff"
  | "Holiday"
  | "SmsLog"
  | "PushLog"
  | "Terminals"
  | "Scoreboards"
  | "Benefit"
  | "BenefitGrant"
  | "Abonement"
  | "AbonementGrant"
  | "Permission"
  | "PermissionGrant"
  | "ParkingSessionsLog"
  | "SystemSettings"
  | "Unknown";

export type Permission = { name: Subjects; scopes: Actions[] };
export type AppAbility = Ability<[Actions, Subjects]>;

export const createAbility = (data: RawRuleOf<AppAbility>[]) => {
  const ability = new Ability<[Actions, Subjects]>(data);
  ability.can = ability.can.bind(ability);
  ability.cannot = ability.cannot.bind(ability);

  return ability;
};

const flat = (arr: Permission[]): RawRuleOf<AppAbility>[] =>
  arr.map(({ name, scopes }) => scopes.map((scope) => ({ subject: name, action: scope }))).flat();

export const AbilityContext = createContext<AppAbility>(null as unknown as AppAbility);

export const AbilityProvider = (props: PropsWithChildren) => {
  const { children } = props;
  const { permissions } = useAuthContext();

  return (
    <AbilityContext.Provider value={createAbility(flat(permissions || []))}>
      {children}
    </AbilityContext.Provider>
  );
};

export const Can = createContextualCan<AppAbility>(AbilityContext.Consumer);
export const useAppAbility = () => useAbility(AbilityContext);
